
import Home from './components/Home/Home';
import Navigation from './components/Navigation/Navigation';
import './index.css';
import Footer from './components/Footer/Footer';
const App= ()=> {
  return (
    <div className="layout" >
      <Navigation />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
