import React from 'react'

const Footer = () => {
  return (
    <><div className="footer-1 wf-section">
    <div className="line-holder on-top"><div className="line"></div></div>
    <div className="w-layout-grid footer-1-contact-grid">
      <div className="vertical-align-center">
        <div className="horizontal-align-center">
          <div className="italic">a/</div>
          <div>234 Nice Street</div>
        </div>
        <div>Beautiful City QD 456</div>
      </div>
      <div className="vertical-align-center">
        <div className="horizontal-align-center">
          <div className="italic">e/</div>
          <a
            href="mailto:hello@template"
            className="link-cta-with-underline w-inline-block"
            ><div>hello@template.com</div>
            <div className="cta-underline"></div
          ></a>
        </div>
        <div className="horizontal-align-center">
          <div className="italic">p/</div>
          <a
            href="tel:(+48)56789012"
            className="link-cta-with-underline w-inline-block"
            ><div>(+48) 567 890 12</div>
            <div className="cta-underline"></div
          ></a>
        </div>
      </div>
    </div>
    <div className="w-layout-grid footer-1-meta-grid">
      <div
        id="w-node-_5592e184-a925-fa8d-e2d1-7f52a600a88e-a600a872"
        className="w-layout-grid footer-1-meta-links"
      >
        <div id="w-node-_5592e184-a925-fa8d-e2d1-7f52a600a890-a600a872">
          ©
        </div>
        <a
          href="/template-settings/instructions"
          className="link-cta-with-underline w-inline-block"
          ><div>Instructions</div>
          <div className="cta-underline"></div></a
        ><a
          href="/template-settings/style-guide"
          className="link-cta-with-underline w-inline-block"
          ><div>Style Guide</div>
          <div className="cta-underline"></div></a
        ><a
          href="/template-settings/licensing"
          className="link-cta-with-underline w-inline-block"
          ><div>Licenses</div>
          <div className="cta-underline"></div></a
        ><a
          href="/template-settings/changelog"
          className="link-cta-with-underline w-inline-block"
          ><div>Changelog</div>
          <div className="cta-underline"></div
        ></a>
      </div>
      <a
        id="w-node-_0982b726-7ef1-b14e-c122-3633aa1f7e32-a600a872"
        href="#top"
        className="back-to-top w-inline-block"
        ><div className="back-to-top-wrap">
          <img
            src="https://assets.website-files.com/613b963b9d55ae9315a4f51e/61eeb49fe129b943a87c9c7d_icon-next-black-4px.svg"
            loading="lazy"
            alt=""
            className="back-to-top-image"
          /></div
      ></a>
      <div
        id="w-node-_26852a9e-cb5f-51df-dff2-a0ede62c2385-a600a872"
        className="w-layout-grid footer-1-meta-links"
      >
        <div
          id="w-node-_5592e184-a925-fa8d-e2d1-7f52a600a89f-a600a872"
          className="horizontal-align-center"
        >
          <div className="italic">site by/</div>
          <a
            href="https://www.fouroom.co/"
            target="_blank"
            className="link-cta-with-underline w-inline-block"
            ><div>Fouroom</div>
            <div className="cta-underline"></div
          ></a>
        </div>
        <div id="w-node-_94e9f2ff-a4e4-3f7a-b68e-76c8fa276b9a-a600a872">
          ✳
        </div>
        <div
          id="w-node-_8cc8d386-20aa-ae69-45b3-4eb4f7aa1585-a600a872"
          className="horizontal-align-center"
        >
          <div className="italic">powered by/</div>
          <a
            href="https://www.webflow.com/"
            target="_blank"
            className="link-cta-with-underline w-inline-block"
            ><div>Webflow</div>
            <div className="cta-underline"></div
          ></a>
        </div>
      </div>
    </div>
  </div></>
  )
}

export default Footer