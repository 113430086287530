import React from 'react'

const Home = () => {
  return (
    <><div id="top" className="_1st-section overflow-hidden wf-section">
    <div className="over-div">
      <div
        data-w-id="021663fd-8819-64a2-03fa-63f3d3739223"
        className="title-2xl"
      >
        Ofelia
      </div>
    </div>
    <div className="w-layout-grid hero-grid-1">
      <div>
        <div className="perspective">
          <div className="transform-positive">
            <div className="image-wrapper">
              <div className="trigger-parallax">
                <div className="image-wrapper-1-1">
                  <img
                    src="https://assets.website-files.com/613b963b9d55ae9315a4f51e/61f7efef34c23c28819cc865_pexels-matt-hardy-2658451.jpg"
                    loading="lazy"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 94vw, (max-width: 991px) 100vw, (max-width: 1279px) 94vw, 100vw"
                    srcset="
                      https://assets.website-files.com/613b963b9d55ae9315a4f51e/61f7efef34c23c28819cc865_pexels-matt-hardy-2658451-p-500.jpeg   500w,
                      https://assets.website-files.com/613b963b9d55ae9315a4f51e/61f7efef34c23c28819cc865_pexels-matt-hardy-2658451-p-800.jpeg   800w,
                      https://assets.website-files.com/613b963b9d55ae9315a4f51e/61f7efef34c23c28819cc865_pexels-matt-hardy-2658451-p-1080.jpeg 1080w,
                      https://assets.website-files.com/613b963b9d55ae9315a4f51e/61f7efef34c23c28819cc865_pexels-matt-hardy-2658451.jpg         1500w
                    "
                    alt=""
                    className="image-background"
                  />
                </div>
              </div>
              <div className="image-overlay"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="vertical-align-center text-align-center">
        <h4 className="title-xs">Artist, Illustrator &amp; Brand Founder</h4>
      </div>
    </div>
    <div className="under-div">
      <div
        data-w-id="24f41ae3-acad-e217-c337-38b4a4d2aa17"
        className="title-2xl"
      >
        Lamos
      </div>
    </div>
  </div>
  <div id="next" className="section padding-top-zero wf-section">
        <div className="content-small">
          <p className="slide-in">
            Ofelia is a multidisciplinary Designer, currently working at Fouroom
            Inc. and specializing in brand identities, editorial design and web.
          </p>
          <div className="relative-overflow-hidden">
            <p className="slide-in">
              She creates design for brands of all shapes and sizes through Art
              Direction, Branding, and Illustration. She works independently for
              a variety of clients in Tech, Fashion, Beauty, and Culture.<a
                href="https://ofelia-template.webflow.io/category/ceramic"
                ><br
              /></a>
            </p>
            <div className="slide-in">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum
              lorem imperdiet. Nunc ut sem vitae risus tristique posuere.
            </div>
            <div className="gradient-white"></div>
          </div>
          <div className="cta-center-wrap">
            <a
              data-w-id="0bb7e1b3-7bb5-8b55-9d83-89a56addfc05"
              href="/about/about-1"
              className="link-cta-with-line margin-top-small w-inline-block"
              ><div
                data-w-id="0bb7e1b3-7bb5-8b55-9d83-89a56addfc06"
                className="label"
              >
                More about me
              </div>
              <div className="cta-wrap">
                <div
                  data-w-id="0bb7e1b3-7bb5-8b55-9d83-89a56addfc09"
                  className="cta-circle"
                ></div></div
            ></a>
          </div>
        </div>
      </div>
      <div className="section-rounded-wrap wf-section">
        <div className="overflow-hidden">
          <div className="section-rounded bg-black">
            <div className="w-layout-grid grid-8-columns">
              <div
                id="w-node-_11aeb35a-8e78-ac03-0114-42e5347c039c-5094a4bd"
                className="contact-title-wrap"
              >
                <h2 className="title-md margin-bottom-zero">
                  Welcome to playground of <br />Ofelia Lamos.
                </h2>
              </div>
              <div
                id="w-node-_11aeb35a-8e78-ac03-0114-42e5347c03ac-5094a4bd"
                className="scrolling-desktop"
              >
                <div className="scrolling-loop-wrapper">
                  <div className="scrolling-loop">
                    <div className="scrolling-loop-item">
                      <div className="scrolling-text-xl">Mockups</div>
                      <div className="scrolling-text-xl">Figurines</div>
                      <div className="scrolling-text-xl">Prints</div>
                      <div className="scrolling-text-xl">Ceramic</div>
                    </div>
                    <div className="scrolling-loop-item">
                      <div className="scrolling-text-xl">Mockups</div>
                      <div className="scrolling-text-xl">Figurines</div>
                      <div className="scrolling-text-xl">Prints</div>
                      <div className="scrolling-text-xl">Ceramic</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="w-node-_11aeb35a-8e78-ac03-0114-42e5347c039f-5094a4bd"
                className="z-index-10"
              >
                <div className="w-dyn-list">
                  <div role="list" className="collection-list w-dyn-items">
                    <div role="listitem" className="w-dyn-item">
                      <div className="perspective">
                        <div className="transform-positive">
                          <a href="/category/ceramic" className="w-inline-block"
                            ><div className="image-wrapper">
                              <div className="trigger-parallax">
                                <img
                                  src="https://assets.website-files.com/61509145daf7a82714b7a7ec/61516eee86e8c44d0f53737c_category-artist-webflow-html-template-01.jpeg"
                                  loading="lazy"
                                  alt="Ceramic"
                                  className="image-cover"
                                />
                              </div>
                              <div className="image-overlay bg-black"></div>
                            </div>
                            <h4 className="shop-thumb-title">Ceramic</h4></a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="w-node-_11aeb35a-8e78-ac03-0114-42e5347c03a6-5094a4bd"
                className="z-index-9"
              >
                <div className="w-dyn-list">
                  <div role="list" className="collection-list w-dyn-items">
                    <div role="listitem" className="w-dyn-item">
                      <div className="perspective">
                        <div className="transform-negative">
                          <a
                            href="/category/prints"
                            className="shop-link-alternative w-inline-block"
                            ><div className="image-wrapper">
                              <div className="trigger-parallax">
                                <img
                                  src="https://assets.website-files.com/61509145daf7a82714b7a7ec/615171e711d21d7a265c2a18_category-artist-webflow-html-template-03.jpeg"
                                  loading="lazy"
                                  alt="Prints"
                                  sizes="(max-width: 479px) 91vw, (max-width: 767px) 48vw, (max-width: 991px) 49vw, (max-width: 1279px) 36vw, 42vw"
                                  srcset="
                                    https://assets.website-files.com/61509145daf7a82714b7a7ec/615171e711d21d7a265c2a18_category-artist-webflow-html-template-03-p-500.jpeg 500w,
                                    https://assets.website-files.com/61509145daf7a82714b7a7ec/615171e711d21d7a265c2a18_category-artist-webflow-html-template-03-p-800.jpeg 800w,
                                    https://assets.website-files.com/61509145daf7a82714b7a7ec/615171e711d21d7a265c2a18_category-artist-webflow-html-template-03.jpeg       960w
                                  "
                                  className="image-cover"
                                />
                              </div>
                              <div className="image-overlay bg-black"></div>
                            </div>
                            <h4 className="shop-thumb-title">Prints</h4></a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="w-node-_11aeb35a-8e78-ac03-0114-42e5347c03c5-5094a4bd"
                className="z-index-8"
              >
                <div className="w-dyn-list">
                  <div role="list" className="collection-list w-dyn-items">
                    <div role="listitem" className="w-dyn-item">
                      <div className="perspective">
                        <div className="transform-positive">
                          <a href="/category/figurines" className="w-inline-block"
                            ><div className="image-wrapper">
                              <div className="trigger-parallax">
                                <img
                                  src="https://assets.website-files.com/61509145daf7a82714b7a7ec/6151722a49f09b706a7ea6d7_category-artist-webflow-html-template-04.jpeg"
                                  loading="lazy"
                                  alt="Figurines"
                                  sizes="(max-width: 479px) 91vw, (max-width: 767px) 48vw, (max-width: 991px) 49vw, (max-width: 1279px) 36vw, 42vw"
                                  srcset="
                                    https://assets.website-files.com/61509145daf7a82714b7a7ec/6151722a49f09b706a7ea6d7_category-artist-webflow-html-template-04-p-500.jpeg 500w,
                                    https://assets.website-files.com/61509145daf7a82714b7a7ec/6151722a49f09b706a7ea6d7_category-artist-webflow-html-template-04-p-800.jpeg 800w,
                                    https://assets.website-files.com/61509145daf7a82714b7a7ec/6151722a49f09b706a7ea6d7_category-artist-webflow-html-template-04.jpeg       960w
                                  "
                                  className="image-cover"
                                />
                              </div>
                              <div className="image-overlay bg-black"></div>
                            </div>
                            <h4 className="shop-thumb-title">Figurines</h4></a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="w-node-_7b20710a-f68e-4140-a1d5-07999167792d-5094a4bd"
              className="shop-cta"
            >
              <a
                data-w-id="07395c69-c26b-d441-6ea5-a1d7b3ea02be"
                href="/pages/shop"
                className="link-cta-with-line margin-top-small w-inline-block"
                ><div className="label on-black">Explore all categories</div>
                <div className="cta-wrap">
                  <div
                    data-w-id="07395c69-c26b-d441-6ea5-a1d7b3ea02c2"
                    className="cta-circle"
                  ></div></div
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div className="section overflow-hidden wf-section">
        <div className="work-heading">
          <div className="overflow-hidden">
            <h6 className="heading-h6">Selected Works</h6>
          </div>
        </div>
        <div className="circled-cms w-dyn-list">
          <div role="list" className="circled-cms-list w-dyn-items">
            <div role="listitem" className="circled-thumb w-dyn-item">
              <a
                href="/project/bring-back-to-colors"
                className="circled-thumb-link w-inline-block"
                ><div className="circled-thumb-image-wrap">
                  <img
                    src="https://assets.website-files.com/61509145daf7a82714b7a7ec/615413bde87a792e64f3a805_work-thumb-02-artist-webflow-html-template.jpeg"
                    loading="eager"
                    alt="Bring Back to Colors"
                    className="circled-thumb-image"
                  />
                </div>
                <div className="circled-thumb-info">
                  <h5 className="circled-thumb-title">Bring Back to Colors</h5>
                  <h6 className="caption">Illustration, Animation</h6>
                </div></a
              >
            </div>
            <div role="listitem" className="circled-thumb w-dyn-item">
              <a
                href="/project/dreaming-about-light"
                className="circled-thumb-link w-inline-block"
                ><div className="circled-thumb-image-wrap">
                  <img
                    src="https://assets.website-files.com/61509145daf7a82714b7a7ec/615415a6a75517796ebfcc9b_work-thumb-01b-artist-webflow-html-template.jpeg"
                    loading="eager"
                    alt="Dreaming About Light"
                    sizes="(max-width: 479px) 50vw, (max-width: 991px) 36vw, (max-width: 1279px) 31vw, 38vw"
                    srcset="
                      https://assets.website-files.com/61509145daf7a82714b7a7ec/615415a6a75517796ebfcc9b_work-thumb-01b-artist-webflow-html-template-p-500.jpeg 500w,
                      https://assets.website-files.com/61509145daf7a82714b7a7ec/615415a6a75517796ebfcc9b_work-thumb-01b-artist-webflow-html-template.jpeg       800w
                    "
                    className="circled-thumb-image"
                  />
                </div>
                <div className="circled-thumb-info">
                  <h5 className="circled-thumb-title">Dreaming About Light</h5>
                  <h6 className="caption">Animation</h6>
                </div></a
              >
            </div>
            <div role="listitem" className="circled-thumb w-dyn-item">
              <a
                href="/project/essential-wear"
                className="circled-thumb-link w-inline-block"
                ><div className="circled-thumb-image-wrap">
                  <img
                    src="https://assets.website-files.com/61509145daf7a82714b7a7ec/6154147d3ee509759d27e40d_work-thumb-03-artist-webflow-html-template.jpeg"
                    loading="eager"
                    alt="Essential Wear"
                    className="circled-thumb-image"
                  />
                </div>
                <div className="circled-thumb-info">
                  <h5 className="circled-thumb-title">Essential Wear</h5>
                  <h6 className="caption">Set Design, Packaging</h6>
                </div></a
              >
            </div>
            <div role="listitem" className="circled-thumb w-dyn-item">
              <a
                href="/project/say-exotique"
                className="circled-thumb-link w-inline-block"
                ><div className="circled-thumb-image-wrap">
                  <img
                    src="https://assets.website-files.com/61509145daf7a82714b7a7ec/615418f8af8ad51b0fa73fde_work-thumb-04d-artist-webflow-html-template.jpeg"
                    loading="eager"
                    alt="Say Exotique"
                    className="circled-thumb-image"
                  />
                </div>
                <div className="circled-thumb-info">
                  <h5 className="circled-thumb-title">Say Exotique</h5>
                  <h6 className="caption">Brand elements, Product Shooting</h6>
                </div></a
              >
            </div>
          </div>
        </div>
      </div>
      <div className="section-under wf-section">
        <div className="section-under-min-height">
          <div className="sticky-top">
            <div className="fullscreen-black">
              <div className="w-layout-grid fullscreen-contact-grid">
                <div
                  id="w-node-_22766f42-f8a8-03e7-c771-7847a65cdc91-5094a4bd"
                  className="contact-heading"
                >
                  I&#x27;m open to Work.
                </div>
                <div
                  id="w-node-b7d554cd-12fa-52fd-4d81-6bc2aec06277-5094a4bd"
                  className="contact-heading"
                >
                  Let&#x27;s collaborate
                </div>
                <div
                  id="w-node-_9ffac49c-ccab-b580-2e6c-2362c68ce8b2-5094a4bd"
                  className="contact-heading"
                >
                  and tell
                </div>
                <div
                  id="w-node-a2096a0d-b1fa-ab13-db93-5ae557bae405-5094a4bd"
                  className="contact-heading"
                >
                  your story.
                </div>
                <a
                  id="w-node-_89164e1c-097e-859f-02bf-8865d9b9ade6-5094a4bd"
                  data-w-id="89164e1c-097e-859f-02bf-8865d9b9ade6"
                  href="/contact/contact-1"
                  className="circular-text-on-dark w-inline-block"
                  ><div className="circular-text-border"></div>
                  <div className="circular-text-icon">
                    <img
                      src="https://assets.website-files.com/613b963b9d55ae9315a4f51e/61eeb4451b74752de5789809_icon-next-white.svg"
                      loading="lazy"
                      alt="Next"
                      className="circular-text-icon-1"
                    /><img
                      src="https://assets.website-files.com/613b963b9d55ae9315a4f51e/61eeb4451b74752de5789809_icon-next-white.svg"
                      loading="lazy"
                      alt="Next"
                      className="circular-text-icon-2"
                    />
                  </div>
                  <div
                    data-w-id="f0bdb232-b9b3-bd4a-8668-1da50eb78975"
                    className="circular-text-wrap"
                  >
                    <div className="circular-text-letter">C</div>
                    <div className="circular-text-letter _02">o</div>
                    <div className="circular-text-letter _03">n</div>
                    <div className="circular-text-letter _04">t</div>
                    <div className="circular-text-letter _05">a</div>
                    <div className="circular-text-letter _06">c</div>
                    <div className="circular-text-letter _07">t</div>
                    <div className="circular-text-letter _08">✳</div>
                    <div className="circular-text-letter _09">C</div>
                    <div className="circular-text-letter _10">o</div>
                    <div className="circular-text-letter _11">n</div>
                    <div className="circular-text-letter _12">t</div>
                    <div className="circular-text-letter _13">a</div>
                    <div className="circular-text-letter _14">c</div>
                    <div className="circular-text-letter _15">t</div>
                    <div className="circular-text-letter _16">✳</div>
                  </div></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
  </>
  )
}

export default Home 